<template>
    <v-app>
        <v-container grid-list-md text-xs-center fluid>
            <h1>Contatos</h1>
            <v-divider class="mb-12" />

            <v-layout wrap>
                <v-flex xs12 sm6>
                    <v-card color="green">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>score</v-icon>
                                {{ successRegister }}
                            </div>
                            Clientes Cadastrados
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="orange">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>score</v-icon>
                                {{ pendingRegister }}
                            </div>
                            Cadastros pendentes
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <!-- <v-switch v-model="onlyProblem" inset label="Somente com problema" /> -->
                </v-flex>
                <v-flex xs12 sm6>
                    <v-text-field label="Buscar por email" v-model="filter" />
                </v-flex>
            </v-layout>

            <v-data-table :headers="headers" :items="desserts" :options.sync="options" :server-items-length="totalDesserts" :loading="loading" class="elevation-1">
                <template v-slot:item.status="{ item }">
                    <v-chip color="green" v-if="item.status == 2">
                        Ok
                    </v-chip>
                    <v-btn v-else :color="getColor(item.status)" @click="sendMail(item)" class="mx-0 secondary--text" small>
                        <v-icon left>mail_outline</v-icon>{{ item.status == 0 ? "Enviar email" : "Enviar novamente email de cadastro" }}
                    </v-btn>
                </template>
            </v-data-table>

            <!-- <v-timeline v-if="contacts.length > 0" dense>
                <v-flex v-for="contact of listWithFilter" :key="contact.id">
                    <v-timeline-item :color="getColor(contact.status)" :icon="getIcon(contact.status)" fill-dot>
                        <v-card :color="getColor(contact.status)" :dark="getColor(contact.status) != 'warning'">
                            <v-card-title>{{ contact.name }}</v-card-title>
                            <v-card-text class="white secondary--text">
                                <v-container grid-list-xl>
                                    <v-layout wrap>
                                        <v-flex xs12 lg6 class="py-0"> <strong>Email:</strong> {{ contact.email }} </v-flex>
                                        <v-flex xs12 lg6 class="py-0"> <strong>Data:</strong> {{ contact.createdIn }} </v-flex>
                                        <v-flex xs12 lg6 class="py-0"> <strong>Telefone:</strong>{{ contact.phone }} </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-btn v-if="contact.status != 2" color="warning" @click="sendMail(contact)" class="mx-0 secondary--text" small>
                                    <v-icon left>mail_outline</v-icon>Enviar novamente email de cadastro
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-flex>
            </v-timeline> -->
        </v-container>
    </v-app>
</template>

<script>
export default {
    // created() {
    //     this.update();
    //     this.timer = setInterval(this.update, 5000);
    // },

    data() {
        return {
            filter: null,
            onlyProblem: false,
            timer: null,
            contacts: [],
            timerPointer: null,

            totalDesserts: 0,
            desserts: [],
            loading: true,
            options: {},
            headers: [
                { text: '#', align: 'start', value: 'id', },
                { text: 'Nome', align: 'start', value: 'name', },
                { text: 'Email', value: 'email' },
                { text: 'Telefone', value: 'phone' },
                { text: 'Data', value: 'createdIn' },
                { text: 'Status', value: 'status' },
            ],
        };
    },

    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
        filter: {
            handler() {
                if (this.timerPointer) {
                    clearTimeout(this.timerPointer);
                }
                this.timerPointer = setTimeout(() => {
                    this.getDataFromApi()
                }, 1000);
            },
        },
    },

    methods: {
        getDataFromApi() {
            let sort = "sort[id]=DESC";
            let filter = "";
            let pagination = "";

            if (this.options.sortBy && this.options.sortBy.length > 0 && this.options.sortDesc && this.options.sortDesc.length > 0) {
                sort = `sort[${this.options.sortBy[0]}]=${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
            }
            if (this.filter) {
                filter = `&email=${this.filter}`;
            }
            if (this.options.itemsPerPage > 0) {
                pagination = `&limit[size]=${this.options.itemsPerPage}&limit[page]=${this.options.page}`;
            }

            this.loading = true
            this.$http
                .get(`api/v2/register?${sort}${pagination}${filter}`)
                .then((result) => {
                    this.desserts = result.items
                    this.totalDesserts = result.total
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                });
        },
        sendMail(contact) {
            this.$http
                .post("api/v2/register", {
                    name: contact.name,
                    email: contact.email,
                    phone: contact.phone,
                })
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Verifique seu email para concluir o cadastro.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Não foi possível enviar sua solicitação.");
                });
        },
        update() {
            this.$http
                .get("api/v2/register?sort[id]=DESC", { continuous: true })
                .then((result) => {
                    if (JSON.stringify(this.contacts) != JSON.stringify(result.items)) this.contacts = result.items;
                })
                .catch(() => {
                    this.contacts = [];
                });
        },
        getColor(status) {
            if (status === 2) return "success";
            else if (status === 1) return "warning";
            else return "error";
        },
        getIcon(status) {
            if (status === 2) return "done";
            else if (status === 1) return "warning";
            else return "error";
        },
    },

    computed: {
        listWithFilter() {
            if (this.filter || this.onlyProblem) {
                return this.contacts.filter((item) => (item.status != 2 || !this.onlyProblem) && (!this.filter || item.name.toLowerCase().includes(this.filter.toLowerCase())));
            } else {
                return this.contacts;
            }
        },
        successRegister() {
            return this.contacts.filter((item) => item.status === 2).length;
        },
        pendingRegister() {
            return this.contacts.filter((item) => item.status !== 2).length;
        },
    },

    // beforeDestroy() {
    //     clearInterval(this.timer);
    // },
};
</script>
